var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "user-group-forms"
  }, [_c("div", {
    staticClass: "clearflex"
  }, [_c("el-button", {
    staticClass: "fl-right",
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.SaveForms
    }
  }, [_vm._v("Save")])], 1), _vm._v(" "), _c("el-transfer", {
    attrs: {
      data: _vm.formTransferData,
      props: {
        key: "key",
        label: "label"
      },
      "filter-placeholder": "enter the key words",
      titles: ["Not Choiced", "Choiced Forms"],
      filterable: "",
      "filter-method": _vm.filterMethod
    },
    model: {
      value: _vm.formTransferModel,
      callback: function callback($$v) {
        _vm.formTransferModel = $$v;
      },
      expression: "formTransferModel"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };